import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "3 Innovative Ways To Source Top Talent",
  "author": "Anthony",
  "date": "2018-05-25T05:26:52.000Z",
  "categories": ["Recruiting"],
  "slug": "3-innovative-ways-to-source-top-talent",
  "draft": false,
  "meta_title": "3 Innovative Ways To Source Top Talent"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Your organization is seeking candidates that will positively impact operations, culture, and bottom line. The problem is, pressing demand for skilled talent has made hiring more difficult than ever. As the overall talent pool shrinks, especially for highly specialized skill sets, recruiters have to adapt to these challenges by sourcing outside the box.`}</p>
    <p>{`When it comes to sourcing the best of the best, business leaders are increasingly understanding the value of hiring veterans. After all, many of the qualities hiring managers look for when sourcing talent — such as leadership, drive, problem-solving skills, and integrity — are evident in veteran candidates.`}</p>
    <p>{`Here, we’ll offer tips on how recruiters can source top talent (which includes `}<a parentName="p" {...{
        "href": "http://blog.purepost.co/implementing-a-successful-veteran-hiring-initiative-that-attracts-top-talent"
      }}>{`veteran talent`}</a>{`!) more innovatively and effectively.`}</p>
    <p><strong parentName="p">{`Seek Out Students`}</strong></p>
    <p>{`In order to overcome the challenge of sourcing candidates with highly specialized or technical skill sets, seeking out students is a solid strategy. Recruiters can plan to attend skill aligned certification classes and university courses that are being held locally or in the nearest metropolitan area. You can also establish school partnerships to build up your candidate pipeline and have professionals with real-life experience come and speak to students and offer guidance.`}</p>
    <p>{`Since many higher-education institutions have special programs and initiatives for veteran students seeking degrees after they have completed their time in the service, employers looking to hire veterans should look to colleges and universities.`}</p>
    <p><strong parentName="p">{`Connect On a Personal Level`}</strong></p>
    <p>{`A great way to catch the attention of skilled candidates is to leverage their areas of interest in order to connect. You can search for blogs, vlogs, or other social resources that relate to extracurricular activities listed on candidates’ resumes. In fact, as per `}<a parentName="p" {...{
        "href": "https://www.careerbuilder.com/advice/social-media-survey-2017"
      }}>{`CareerBuilder`}</a>{` -`}</p>
    <h3>{`70% of employers are snooping candidates’ social media profiles`}</h3>
    <p>{`You should also consider reaching out to interest-based organizations or meetups that align with requisitions. There are countless local and national nonprofit organizations dedicated to connecting veterans and employers, so be sure to research the ones that serve your area. Larger government-sponsored resources include the `}<a parentName="p" {...{
        "href": "http://www.va.gov/"
      }}>{`U.S. Department of Veterans Affairs`}</a>{`, the `}<a parentName="p" {...{
        "href": "https://www.dol.gov/vets/"
      }}>{`Department of Labor's Veterans' Employment and Training Service (VETS)`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://www.uschamberfoundation.org/hiring-our-heroes"
      }}>{`Hiring Our Heroes`}</a>{`, which is a U.S. Chamber of Commerce affiliate.`}</p>
    <p>{`Being able to speak with potential candidates, personally, helps to create a more effective pipeline.`}</p>
    <p><strong parentName="p">{`Use a Talent Sourcing Tool`}</strong></p>
    <p>{`Talent sourcing tools can accelerate your recruiting efforts in innovative, and even little-known, ways. Consider using a browser extension like `}<a parentName="p" {...{
        "href": "https://breezy.hr/"
      }}>{`Breezy HR`}</a>{` that imports candidates from around the web including directly from LinkedIn. Breezy allows recruiters to post to more than 2,000 job boards and easily manage their candidate pipeline on any device.`}</p>
    <p>{`You can quickly tap into the right talent for your organization by leveraging a talent acquisition platform. Purepost is a veteran talent acquisition platform that screens candidates via military translation technology. Being able to quickly and accurately translate veteran candidate skills makes the sourcing process a lot easier for recruiters. Knowing exactly which skilled veterans best match your open jobs will amplify your sourcing process and bring more value to your organization — not to mention place veterans in roles they’re best suited for and increase retention. If you’d like more information about how `}<a parentName="p" {...{
        "href": "https://mailchi.mp/purepost.co/purepostco-recruiter-early-access-registration"
      }}>{`Purepost`}</a>{` can accelerate your hiring process, request a call with a dedicated member of our team!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      